import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const SubPhotographyPage = () => {
  return (
    <Container id="category-container">
      <MenuBar activeHeader="Work" />
      <section id="work-page">
        <div className="categories-container">
          <Row className="align-items-center">
            <Col xs={12} md={12}>
              <div id="hero-wrap">
                <header className="subPageHeader">
                  <h1>Photography</h1>
                  <p>
                    Welcome to my photography portfolio, a collection that spans
                    stills, architecture, digital manipulation, and event
                    photography, with a special focus on portraits. I am
                    passionate about capturing moments and people in their most
                    authentic and candid states. Whether it's the intricate
                    details of architecture or the fleeting emotions in a
                    portrait, my work is driven by a love for storytelling
                    through the lens. Explore my portfolio to see how I blend
                    technical skill with a deep appreciation for the beauty of
                    life's moments.
                  </p>
                </header>
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/photography/architecture`}
                key="architecture"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">Architecture</h2>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/photography/digitalart`}
                key="digitalart"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">Digital Art</h2>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/photography/landscape`}
                key="landscape"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">Landscape</h2>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="justify-content-center"
              style={{ display: "flex" }}
            >
              <Link
                to={`/work/photography/portraits`}
                key="portraits"
                style={{ textDecoration: "none" }}
              >
                <div className="categoryWrapper">
                  <h2 className="category-text">Portraits</h2>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default SubPhotographyPage;
