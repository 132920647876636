import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import categoriesData from "../data/categories.json";

const MenuBar = ({ activeHeader }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div id="menu-bar">
      <Row className="align-items-center">
        <Col xs={12} md={{ span: 3 }}>
          <div>
            <Link to={`/`}>
              <h1
                className={
                  activeHeader == "Home" ? "center-menu-text" : "inactiveHeader"
                }
              >
                Nadine Bernardino
              </h1>
            </Link>
          </div>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Row className="align-items-center">
            <Col className="submenuOption" xs={12} md={{ span: 3, offset: 3 }}>
              <Link to={`/about`}>
                <h1
                  className={
                    activeHeader == "About"
                      ? "center-menu-text"
                      : "inactiveHeader"
                  }
                >
                  About
                </h1>
              </Link>
            </Col>

            <Col xs={12} md={3}>
              <div
                id="workmenu-div"
                onMouseLeave={() => setShowDropdown(false)}
                onMouseOver={() => setShowDropdown(true)}
              >
                <Link to={`/work`} style={{ display: "inline-block" }}>
                  <h1
                    className={
                      activeHeader == "Work"
                        ? "center-menu-text"
                        : "inactiveHeader"
                    }
                  >
                    Work
                  </h1>
                </Link>
                <DropdownButton
                  show={showDropdown}
                  id="work-dropdown"
                  title=""
                  className="dropdown-button"
                >
                  {categoriesData.map((category) => (
                    <Dropdown.Item
                      key={category.categoryName}
                      as={Link}
                      to={
                        category.categoryName === "writing"
                          ? "/writing"
                          : category.categoryName === "videos"
                          ? "/videos"
                          : `/work/${category.categoryName}`
                      }
                    >
                      {category.categoryName}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </Col>

            <Col xs={12} md={3}>
              <Link to={`/contact`}>
                <h1
                  className={
                    activeHeader == "Contact"
                      ? "center-menu-text"
                      : "inactiveHeader"
                  }
                >
                  Contact
                </h1>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MenuBar;
