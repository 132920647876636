import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import Masonry from "react-masonry-css";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import LoadingSpinner from "./LoadingSpinner";
import Footer from "./Footer";

const ProjectPage = () => {
  const [loading, setLoading] = useState(true);
  const { categoryName, projectName } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const delay = 2000;

  useEffect(() => {
    let timeoutId;
    const checkReadyState = () => {
      if (document.readyState === "complete") {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, delay);
      } else {
        setTimeout(checkReadyState, 100); // check again after 100ms
      }
    };
    checkReadyState();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  const handleModalOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const categoryProjects = projectsData.find(
    (proj) => proj.category === categoryName
  ).projects;

  console.log(categoryProjects);

  const projectDisplayName = categoryProjects.find(
    (proj) => proj.projectName === projectName
  ).displayTitle;

  const selectedProject = categoryProjects.find(
    (x) => x.projectName === projectName
  );

  console.log(selectedProject);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container id="category-container">
          <MenuBar />
          <section id="project-category-page">
            <Row>
              <Col xs={12}>
                <h1 style={{ margin: "20px", textAlign: "center" }}>
                  {projectDisplayName}
                </h1>
                <p>{selectedProject.projectDescription}</p>
              </Col>
            </Row>
          </section>
          <Masonry
            breakpointCols={{
              default: 4,
              1100: 3,
              700: 2,
              500: 1,
            }}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {selectedProject.photos.map((photo, index) => (
              <div
                key={index}
                className="masonry-grid_item"
                style={{ cursor: "pointer" }}
              >
                <img
                  onClick={() =>
                    handleModalOpen(
                      `../../images/${categoryName}/${projectName}/${photo}`
                    )
                  }
                  src={`../../images/${categoryName}/${projectName}/${photo}`}
                  alt={photo}
                />
              </div>
            ))}
          </Masonry>
          {modalOpen && (
            <div className="modal imgmodal">
              <div className="modal-content imgmodal-content">
                <img src={selectedImage} alt="Project Image" />
                <button className="imgmodalCloseBtn" onClick={handleModalClose}>
                  Close
                </button>
              </div>
            </div>
          )}
          <Footer />
        </Container>
      )}
    </div>
  );
};

export default ProjectPage;
