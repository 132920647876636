import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import subcategoriesData from "../data/subcategories.json";
import { Container, Row, Col } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const ProjectCategory = ({ subcategory }) => {
  const [loading, setLoading] = useState(true);
  const { categoryName } = useParams();
  const delay = 2000;

  const subCategoryName = subcategory
    ? subcategoriesData
        .find((cat) => cat.categoryName === subcategory)
        .subcategories.find((subcat) => subcat.subcategoryName === categoryName)
        .displayName
    : "";

  useEffect(() => {
    let timeoutId;
    const checkReadyState = () => {
      if (document.readyState === "complete") {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, delay);
      } else {
        setTimeout(checkReadyState, 100); // check again after 100ms
      }
    };
    checkReadyState();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  const truncateText = (text) => {
    if (text.length > 25) {
      return text.substring(0, 25) + "...";
    }
    return text;
  };

  // Use the category param to fetch the corresponding projects
  const heroImage = subcategory
    ? subcategoriesData
        .find((cat) => cat.categoryName === subcategory)
        .subcategories.find((subcat) => subcat.subcategoryName === categoryName)
        .backgroundImage
    : categoriesData.find((cat) => cat.categoryName === categoryName)
        .backgroundImage;

  const categoryDesc = subcategory
    ? subcategoriesData
        .find((cat) => cat.categoryName === subcategory)
        .subcategories.find((subcat) => subcat.subcategoryName === categoryName)
        .categoryDescription
    : categoriesData.find((cat) => cat.categoryName === categoryName)
        .categoryDescription;
  const projects = projectsData.find(
    (cat) => cat.category === categoryName
  ).projects;

  console.log(`test image ${heroImage}`);
  console.log(`test desc ${categoryDesc}`);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container id="category-container">
          <MenuBar />
          <section id="project-category-page">
            <Row>
              <Col xs={12}>
                <h1 style={{ margin: "20px", textAlign: "center" }}>
                  {subcategory ? subCategoryName : categoryName}
                </h1>
                <p>{categoryDesc}</p>
              </Col>
            </Row>
          </section>
          <Row style={{ marginBottom: "25em" }}>
            {projects.map((project) => (
              <Col xs={12} md={3} style={{ padding: "0" }}>
                <Link
                  to={`/work/${categoryName}/${project.projectName}`}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="projectHeroImageWrapper"
                    style={{
                      backgroundImage: `url(..${
                        subcategory ? "/.." : ""
                      }/images/${categoryName}/${encodeURI(
                        project.projectName
                      )}/projecthero.png)`,
                    }}
                  >
                    <div className="projectHero-tile-overlay"></div>
                    <h2 className="projectHero-text">
                      {truncateText(project.displayTitle.split(".").shift())}
                    </h2>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
          <Footer />
        </Container>
      )}
    </div>
  );
};

export default ProjectCategory;
