import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const AboutPage = () => {
  return (
    <Container id="category-container">
      <MenuBar activeHeader="About" />
      <section id="about-page">
        <Row>
          <Col xs={12} md={6} className="about-logo-wrapper">
            <div id="logo-col">
              <img
                id="about-logo"
                src="../popjpegs/Nadine.jpg"
                alt="Header Img"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div>
              <h1 style={{ textAlign: "center" }}>About Nadine</h1>
              <p>
                <a
                  id="about-special"
                  href="https://www.philstar.com/headlines/2021/06/07/2103771/250-rescued-trafficked-badjaos-be-brought-back-zamboanga"
                >
                  Nadine
                </a>{" "}
                is a passionate and accomplished communications professional
                with a bachelor’s degree in Organizational Communication from De
                La Salle University-Manila. Since 2015, she has honed her
                expertise in public relations within the government sector,
                while also expanding her creative talents in design,
                photography, and video production.
                <br />
                <br />
                One of Nadine’s most notable achievements in video-making was
                her volunteer work for the Yellow Boat of Hope Foundation
                (YBHF). At just 19, she created an organizational video that won
                1st place at the Good Story Competition in 2014, hosted by Our
                Better World, an initiative of the Singapore International
                Foundation, making her the youngest entrant in the competition’s
                history. The video was later recognized as The Good Story of the
                Year 2015, with the prize money used to fund the construction of
                a boat that now helps 50 children attend school. She later
                became one of YBHF’s Ambassadors.
                <br />
                <br />
                Nadine’s creative endeavors have garnered international
                recognition, including awards from India and Thailand, where she
                was honored by Princess Soamsawali of Thailand herself. Her
                dedication to using creativity for community impact was further
                recognized with a Leadership Award given by Fortune's Top 100
                Leaders, Frances Hesselbein, at the University of Pittsburgh in
                2015.
                <br />
                <br />
                Currently, Nadine is completing her master’s degree in
                Professional Communication at the University of San Francisco,
                with a concentration in Strategic Communication. She has a
                passion for creating impactful campaigns and events that
                resonate with audiences and enjoys volunteering her skills for
                meaningful causes. Nadine is always eager to collaborate on
                projects that challenge the status quo and make a lasting
                difference.
                <br />
                <br />
                Challenge the established with her!
                <br />
                <br />
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <Footer />
    </Container>
  );
};

export default AboutPage;
