import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const WorksPage = () => {
  return (
    <Container id="category-container">
      <MenuBar activeHeader="Work" />
      <section id="work-page">
        <div className="categories-container">
          <Row className="align-items-center">
            <Col xs={12} md={12}>
              <div id="hero-wrap">
                <header className="subPageHeader">
                  <h1>Work</h1>
                  <p>
                    Visual, Strategic, and Public and Media Relations—these
                    three words capture the essence of my work as a
                    Communications Practitioner. In this portfolio, you will
                    find creative projects in photography, print, and video that
                    highlight my expertise in visual communication.
                    Additionally, examples of proposed campaigns, crisis
                    management strategies, and event plans demonstrate my
                    strengths in project management and branding as a strategic
                    communicator. You will also find samples of my published
                    writing, which showcase my proficiency in connecting with
                    the public and media. Each piece is thoughtfully crafted to
                    convey the brand's message effectively. Through these works,
                    I strive to uplift society and bridge gaps in access to
                    information, reflecting my dedication to making a meaningful
                    impact.
                  </p>
                </header>
              </div>
            </Col>
            {categoriesData.map((category) => (
              <Col
                xs={12}
                md={4}
                className="justify-content-center"
                style={{ display: "flex" }}
              >
                <Link
                  to={
                    category.categoryName === "writing"
                      ? "/writing"
                      : category.categoryName === "videos"
                      ? "/videos"
                      : `/work/${category.categoryName}`
                  }
                  key={category.categoryName}
                  style={{ textDecoration: "none" }}
                >
                  <div className="categoryWrapper">
                    <h2 className="category-text">{category.categoryName}</h2>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default WorksPage;
